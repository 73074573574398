import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';
import InfoIcon from '@mui/icons-material/Info';
import ContactsIcon from '@mui/icons-material/Contacts';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import DraftsIcon from '@mui/icons-material/Drafts';
import BoltIcon from '@mui/icons-material/Bolt';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import { connect } from "react-redux";
import { logout } from '../store/actions/authActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Drawer, FormControl, Grid, InputAdornment, InputLabel, NativeSelect, Select, Tooltip } from '@mui/material';
import { MainInput, MainSearch, centered_flex_box, left_flex_box, right_flex_box } from './Styles';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import StarsIcon from '@mui/icons-material/Stars';
import Logo from "./logo.png"
import { Image } from 'antd';
import Cookies from "js-cookie";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { AccountCircleOutlined, Analytics, ArrowForward, CardGiftcard, Close, ExpandMore, Favorite, Inventory, Message, MonetizationOn, PriceCheck, QuestionAnswer, QuestionMark, RequestQuote, Search, Store } from '@mui/icons-material';
import LoginModal from './LoginModal';
import ImageComponent from './ImageComponent';

function MenuAppBar({ auth, logout }) {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const [search, setSearch] = useState(null);

    const user = auth?.user;
    const token = auth?.token || '';
    const header = token.split(' ')
    const role = header[0]
    const location = useLocation();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setDrawerOpen(true)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setDrawerOpen(false)
    };

    const handleLogin = () => {
        setAnchorEl(null);
        if (isMobile) {
            route("/login")
        } else {
            setLoginOpen(true)
        }
    };

    const handleLogout = () => {
        setAnchorEl(null);
        logout();
    };

    const handleHome = () => {
        setAnchorEl(null);
        navigate("/");
    };

    const handleProfile = () => {
        setAnchorEl(null);
        let path = "/" + role + "/profile";
        navigate(path);
    };

    const route = (path) => {
        setAnchorEl(null);
        navigate(path);
    }

    const menuItems = <>
        <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
            <MobileView><IconButton className='position-absolute top-0 end-0' onClick={handleClose}><Close /></IconButton></MobileView>
            <ImageComponent preview={false} src={Logo} height={100} width={100} />
        </Box>
        < hr />
        <MenuItem sx={{color: "var(--secColor)"}} onClick={() => route("/about")}><InfoIcon /><Typography marginX={2}>About Us</Typography></MenuItem>
        <MenuItem sx={{color: "var(--secColor)"}} onClick={() => route("/contact")}><Message /><Typography marginX={2}>Contact Us</Typography></MenuItem>
        <MobileView>
            <MenuItem sx={{color: "var(--secColor)"}} onClick={() => route("/demo")}><QuestionMark /><Typography marginX={2}>How It Works</Typography></MenuItem>
            <MenuItem sx={{color: "var(--secColor)"}} onClick={() => route("/pricing")}><MonetizationOn /><Typography marginX={2}>Packages</Typography></MenuItem>
        </MobileView>
        <MenuItem sx={{color: "var(--secColor)"}} onClick={() => route("/faq")}><QuestionAnswer /><Typography marginX={2}>FAQs</Typography></MenuItem>
        <hr />
        {!user && <MenuItem sx={{color: "var(--secColor)"}} onClick={() => handleLogin()}><LoginIcon /><Typography marginX={2}>Login</Typography></MenuItem>}
        {user && role === "User" && <MenuItem sx={{color: "var(--secColor)"}} onClick={() => route("/user/account")}><AccountCircle /><Typography marginX={2}>My Account</Typography></MenuItem>}
        {user && role === "User" && <MenuItem sx={{color: "var(--secColor)"}} onClick={() => route("/user/voucher")}><CardGiftcard /><Typography marginX={2}>Redeem Voucher</Typography></MenuItem>}
        {user && role === "User" && <MenuItem sx={{color: "var(--secColor)"}} onClick={() => route("/user/orders")}><ReceiptIcon /><Typography marginX={2}>My Orders</Typography></MenuItem>}
        {user && role === "User" && <MenuItem sx={{color: "var(--secColor)"}} onClick={() => route("/user/events")}><EventIcon /><Typography marginX={2}>My Events</Typography></MenuItem>}
        {user && <MenuItem onClick={handleLogout}><LogoutIcon color='error' /><Typography color='error' marginX={2}>Logout</Typography></MenuItem>}
    </>


    return (
        <Box sx={{ flexGrow: 1, color: "var(--secColor)", paddingBottom: 9 }}>
            <BrowserView>
                <AppBar sx={{ marginBottom: 2 }} position="fixed" color='primary'>
                    <Toolbar sx={{ margin: 1 }}>
                        <Grid container>
                            <Grid item xs={3} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <ImageComponent preview={false} src={Logo} height={80} width={80} onClick={handleHome} style={{ cursor: "pointer" }} />
                            </Grid>
                            <Grid item xs={6} sx={{ ...centered_flex_box }}>
                                <IconButton sx={{ color: "var(--secColor)" }} onClick={() => route("/demo")}><Typography marginX={1} fontWeight={"bold"}>How It Works</Typography></IconButton><div class="vr" style={{ height: "50%", marginTop: "auto", marginBottom: "auto" }} />
                                <IconButton sx={{ color: "var(--secColor)" }} onClick={() => route("/pricing")}><Typography marginX={1} fontWeight={"bold"}>Pricing</Typography></IconButton><div class="vr" style={{ height: "50%", marginTop: "auto", marginBottom: "auto" }} />
                                <IconButton sx={{ color: "var(--secColor)" }} onClick={() => route("/about")}><Typography marginX={1} fontWeight={"bold"}>About Us</Typography></IconButton>
                            </Grid>
                            <Grid item xs={3} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                {user && role === "User" && <IconButton onClick={() => route("/user/account")}><AccountCircle sx={{ color: "var(--secColor)" }} /></IconButton>}
                                {!user && <IconButton onClick={() => handleLogin()}><AccountCircleOutlined sx={{ color: "var(--secColor)" }} /></IconButton>}
                                <IconButton onClick={handleMenu}><MenuIcon sx={{ color: "var(--secColor)" }} /></IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar >
                    <React.Fragment key={"right"}>
                        <Drawer
                            anchor={"right"}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <Box sx={{ width: "25vw", padding: 1 }}>
                                {menuItems}
                            </Box>
                        </Drawer>
                    </React.Fragment>
                </AppBar >
                <LoginModal open={loginOpen} handleAccept={() => setLoginOpen(false)} handleClose={() => setLoginOpen(false)} />
            </BrowserView>
            <MobileView>
                <AppBar sx={{ marginBottom: 2 }} position="fixed" color='primary'>
                    <Toolbar>
                        <Grid container>
                            <Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <IconButton onClick={() => navigate(-1)}><ArrowBackIcon sx={{ color: "var(--secColor)" }} /></IconButton>
                                <ImageComponent width={50} height={50} preview={false} src={Logo} onClick={handleHome} style={{ cursor: "pointer", padding: 5 }} />
                            </Grid>
                            <Grid item xs={6}>

                            </Grid>
                            <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                {user && role === "User" && <IconButton onClick={() => route("/user/account")}><AccountCircle sx={{ color: "var(--secColor)" }} /></IconButton>}
                                {!user && <IconButton onClick={() => handleLogin()}><AccountCircleOutlined sx={{ color: "var(--secColor)" }} /></IconButton>}
                                <IconButton onClick={handleMenu}><MenuIcon sx={{ color: "var(--secColor)" }} /></IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar >
                    <React.Fragment key={"top"}>
                        <Drawer
                            anchor="top"
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <Box sx={{ padding: 2 }}>
                                {menuItems}
                            </Box>
                        </Drawer>
                    </React.Fragment>
                </AppBar >
            </MobileView>
        </Box>
    );
}

const mapStateToProps = (state) => ({
    auth: state?.auth,
    categories: state?.records?.categories
});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(MenuAppBar);