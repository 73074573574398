import { Share } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

function ShareButton({ title, text, url }) {
    const handleShare = async () => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title,
                    text,
                    url
                });
            } else {
                alert('Web Share API is not supported in this browser.');
            }
        } catch (error) {
            console.error('Error sharing:', error.message);
        }
    };

    return (
        <IconButton onClick={handleShare}>
            <Share />
        </IconButton>
    );
}

export default ShareButton;
