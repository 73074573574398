import * as React from "react";
import {
    Container,
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

export const Refund = ({ }) => {
    const navigate = useNavigate();

    return (
        <Container sx={{ padding: 10 }}>
            <>
                <h1><strong>POVPix Refund Policy</strong></h1>

                <p>We pride ourselves in having a "No Questions Asked" refund policy</p>

                <p>Refunds may take 1-2 billing cycles to appear on your credit card statement, depending on your credit card company.</p>

                <strong>Note:</strong> We can only issue refunds for events that have not started yet

                <br />
                <p>For more information please contact our customer service:</p>
                <li><a className="a2" href="mailto:info@pov-pix.com"><strong>info@pov-pix.com</strong></a></li>
                <li><a className="a2" href="tel:+201207325333"><strong>+201207325333</strong></a></li>
            </>
        </Container>
    );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Refund);
