import * as React from "react";
import {
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Box,
    MenuItem,
    InputAdornment,
    IconButton,
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    CircularProgress
} from "@mui/material";
import { connect } from "react-redux";
import { MainInput, centered_flex_box, main_button } from "./Styles";
import { isMobile } from "react-device-detect";
import MuiPhoneNumber from "material-ui-phone-number";
import { useNavigate } from "react-router-dom";
import { setReminder } from "../store/actions/guestActions";

function ReminderModal({ open, handleClose, eventId, setReminder, isWaiting }) {

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const reminderInfo = {
            eventId,
            email: data.get('email')
        }
        setReminder(reminderInfo, navigate)
        handleClose()
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ color: "var(--appBg)", minWidth: "100vw" }}
        >
            <Box component="form" onSubmit={handleSubmit} sx={{ padding: 2 }}>
                <Typography variant="h4">Set a Reminder</Typography>
                <Typography variant="h6" fontWeight="light">We will send an email to remind you to upload all your media tomorrow</Typography>
                <MainInput
                    margin="normal"
                    fullWidth
                    required
                    type="email"
                    name="email"
                    label="Email"
                    id="email"
                />
                <Button type='submit' sx={{ ...main_button }} disabled={isWaiting} fullWidth>{isWaiting ? <CircularProgress sx={{ color: "var(--secColor)" }} /> : "Set"}</Button>
            </Box>
        </Dialog >
    );
}

const mapStateToProps = (state) => ({
    isWaiting: state?.wait?.isLoading
});

const mapDispatchToProps = { setReminder };

export default connect(mapStateToProps, mapDispatchToProps)(ReminderModal);
