import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainInput, centered_flex_box, main_button, sec_button } from '../../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import Logo from "../../app/components/logo.png"
import { Image } from 'antd';
import ImageComponent from '../../app/components/ImageComponent';

export const Home = ({ }) => {

    const navigate = useNavigate();

    return (
        <Container sx={{ minWidth: isMobile ? "100% !important" : "90% !important" }}>
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <Box sx={{ minWidth: "100vw", minHeight: isMobile ? "30vh" : "50vh", mt: -2, backgroundImage: "url(https://pov-pix.s3.eu-west-3.amazonaws.com/Brand/Assets/BG4.jpg)", backgroundSize: 'cover', backgroundPosition: 'center' }} />
                <Box sx={{ minWidth: "100vw", ...(isMobile ? { padding: 2, mb: 2 } : { p: 10 }), ...centered_flex_box, flexDirection: "column" }}>
                    <Typography textAlign="center" sx={{ color: "var(--secColor)" }} gutterBottom={2} variant='h3'>Your Digital Album with POV Pix</Typography>
                    <Typography textAlign="center" sx={{ fontStyle: "italic", color: "var(--secColor3)" }} fontSize={25}>
                        Save the memories from your event to enjoy for a lifetime.
                        Because it only happens once.
                        And do not worry about storage space.
                    </Typography>
                    <Typography fontSize={20} fontStyle="italic" gutterBottom={2} textAlign="center">
                        It takes just <strong>2 minutes</strong> from purchase to event creation!
                    </Typography>
                    <Button sx={{ ...main_button, my: 1 }} onClick={() => navigate("/pricing")}>
                        Find A Bundle
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
