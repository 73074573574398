import * as React from 'react';
import { Typography, Table, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Card, Pagination, ToggleButtonGroup, ToggleButton, Paper, TableContainer, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainDateInput, MainInput, centered_flex_box, main_button, right_flex_box } from '../../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { Image } from 'antd';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { getOrders } from '../../app/store/actions/usersActions';
import { useState } from 'react';
import OrderCard from '../../app/components/OrderCard';
import { DashboardCustomize, FilterAlt, Inbox, TableChart } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export const UserOrders = ({ getOrders, orders, isLoading }) => {

    const navigate = useNavigate()

    const initialOrderFilters = {
        page: 1,
        limit: 100,
        status: undefined,
    }
    const [orderFilters, setOrderFilters] = useState(initialOrderFilters)
    const { page, limit } = orderFilters

    const handlePageChange = (event) => {
        setOrderFilters({ ...orderFilters, page: parseInt(event.target.innerText) })
    }

    useEffect(() => {
        getOrders(orderFilters)
    }, [orderFilters])

    return (
        <Box sx={{ paddingX: isMobile ? 2 : 10, paddingTop: isMobile ? 0 : 10 }}>
            <Box>
                {!isLoading ? (<>
                    {
                        <Container maxWidth="md" component="main">
                            <Grid container spacing={5} justifyContent="center">
                                {orders?.docs?.map((order) => (
                                    <OrderCard order={order} />
                                ))}
                            </Grid>
                        </Container>
                    }
                    {orders?.docs?.length === 0 && (
                        <Box>
                            <Inbox fontSize="large" />
                            <Typography textAlign="center" fontSize={40}>No Orders</Typography>
                        </Box>
                    )}
                </>
                ) : (
                    <Box sx={centered_flex_box}>
                        <CircularProgress sx={{ color: "var(--secColor)" }} />
                    </Box>
                )}
                <Box sx={{ ...centered_flex_box, mx: 1, p: 1 }}>
                    <Pagination count={orders?.results?.pages || 1} page={page} onChange={handlePageChange} />
                </Box>
            </Box>
        </Box >
    );
}

const mapStateToProps = (state) => ({
    orders: state?.records?.orders,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = { getOrders };

export default connect(mapStateToProps, mapDispatchToProps)(UserOrders);