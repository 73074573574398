import { Add, Celebration, Diamond, Email, ExpandMore, Facebook, Phone, Send, Upload, Visibility, WhatsApp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Chip, CircularProgress, Dialog, Grid, Typography } from "@mui/material";
import { MainInput, centered_flex_box, main_button, right_flex_box } from "./Styles";
import { isMobile } from "react-device-detect";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Image, notification } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import MuiPhoneNumber from "material-ui-phone-number";
import ReactFileReader from "react-file-reader";
import { config } from "../config";
import { uploadFile } from "react-s3";
import { orderPriceList } from "../store/actions/usersActions";
import moment from "moment";
import CheckoutModal from "./CheckoutModal";
import { useNavigate } from "react-router-dom";
import ShareButton from "./ShareButton";

function GuestbookCard({ message }) {

    const navigate = useNavigate()

    return (
        <Grid
            item
            key={message._id}
            xs={isMobile ? 12 : 6}
        >
            <Card>
                <CardContent>
                    <Box>
                        <Typography textAlign="justify" variant="h4" color="text.primary">
                            {message.content}
                        </Typography>
                        <Typography variant="h6" color="text.secondary" fontStyle="italic">
                            ~ {message.guest.name}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GuestbookCard);