import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
    Typography,
    Box,
    Container,
    TextField,
    CssBaseline,
    Button,
    Avatar,
    Select,
    MenuItem,
    InputLabel,
    FormHelperText,
    FormControl,
    CircularProgress,
    Chip,
    Tooltip,
    Card,
} from "@mui/material";
import { connect } from "react-redux";
import OutboxIcon from "@mui/icons-material/Outbox";
import { uploadFile } from "react-s3";
import {
    centered_flex_box,
    MainInput,
    MainInputLabel,
    MainTextArea,
    main_button,
    reject_button,
    sec_button,
    StyledInput,
} from "../../app/components/Styles";
import { useNavigate, useParams } from "react-router-dom";
import ReactFileReader from "react-file-reader";
import InfoCard from "../../app/components/InfoCard";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import ImageComponent from "../../app/components/ImageComponent";

export const Demo = ({ }) => {
    const navigate = useNavigate();

    return (
        <Container>
            <Box sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}>
                <Typography
                    fontSize={30}
                    fontWeight="bold"
                    textAlign="center"
                    color={"var(--secColor)"}
                >
                    How It Works
                </Typography>
                <hr
                    style={{
                        width: "10%",
                        opacity: "100%",
                        "border-top": "solid var(--secColor) 3px",
                    }}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <Typography fontWeight="bold" textAlign="center" fontSize={isMobile ? 24 : 28}>1.</Typography>
                        <Typography textAlign="center" fontSize={isMobile ? 18 : 22}>Purchase a bundle that works for you and the event you're hosting. All our bundles offer unlimited storage</Typography>
                        <Button sx={{ ...main_button }} onClick={() => navigate("/pricing")}>See Bundles</Button>
                    </Grid>
                    <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <Typography fontWeight="bold" textAlign="center" fontSize={isMobile ? 24 : 28}>2.</Typography>
                        <Typography textAlign="center" fontSize={isMobile ? 18 : 22}>Create your event, it takes less than 2 minutes!</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <Typography fontWeight="bold" textAlign="center" fontSize={isMobile ? 24 : 28}>3.</Typography>
                        <Typography textAlign="center" fontSize={isMobile ? 18 : 22}>Share the link with your guests or incorporate the QR Code in your event prints</Typography>
                        <ImageComponent width={isMobile ? "100%" : "30%"} height={isMobile ? "100%" : "auto"} preview={false} src="https://pov-pix.s3.eu-west-3.amazonaws.com/Brand/Assets/Table.webp" />
                    </Grid>
                    <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <Typography fontWeight="bold" textAlign="center" fontSize={isMobile ? 24 : 28}>4.</Typography>
                        <Typography textAlign="center" fontSize={isMobile ? 18 : 22}>Guests can join your event using the link / QR Code and can upload freely during the time specified in the bundle. Uploads are unlimited with no storage cap, stored securely on the cloud</Typography>
                        <Typography textAlign="center" fontSize={isMobile ? 18 : 22}>You can also set up a DIY photobooth that syncs automatically to your album</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <Typography fontWeight="bold" textAlign="center" fontSize={isMobile ? 24 : 28}>5.</Typography>
                        <Typography textAlign="center" fontSize={isMobile ? 18 : 22}>View what guests uploaded in the event gallery and relive the best moments of the day, captured genuinely</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <Typography fontWeight="bold" textAlign="center" fontSize={isMobile ? 24 : 28}>6.</Typography>
                        <Typography textAlign="center" fontSize={isMobile ? 18 : 22}>We will remind you before the event expires to download all the uploaded media in one click</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <Typography fontWeight="bold" textAlign="center" fontSize={isMobile ? 24 : 28}>7.</Typography>
                        <Typography textAlign="center" fontSize={isMobile ? 18 : 22}>Your photos are <strong>permanently deleted</strong> after event expiry</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Demo);
