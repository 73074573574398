import { Add, Celebration, Diamond, Edit, Email, ExpandMore, Facebook, MenuBook, Phone, Send, Upload, Visibility, WhatsApp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Chip, CircularProgress, Dialog, Grid, Typography } from "@mui/material";
import { MainInput, centered_flex_box, main_button, right_flex_box } from "./Styles";
import { isMobile } from "react-device-detect";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Image, notification } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import MuiPhoneNumber from "material-ui-phone-number";
import ReactFileReader from "react-file-reader";
import { config } from "../config";
import { uploadFile } from "react-s3";
import { orderPriceList } from "../store/actions/usersActions";
import moment from "moment";
import CheckoutModal from "./CheckoutModal";
import { useNavigate } from "react-router-dom";
import ImageComponent from "./ImageComponent";

function EventCard({ event, user }) {

    const navigate = useNavigate()

    return (
        <Grid
            item
            key={event._id}
            xs={isMobile ? 12 : 6}
        >
            <Card>
                <CardHeader
                    title={event.name}
                    subheader={`${moment(event.date).format("ll")} ${event.expired ? "(Expired)" : ""}`}
                    titleTypographyProps={{ textAlign: 'center' }}
                    action={event.bundle.name === 'Wedding' ? <Diamond /> : (event.bundle.name === "Prom" ? <Celebration /> : null)}
                    subheaderTypographyProps={{
                        textAlign: 'center',
                    }}
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[200]
                                : theme.palette.grey[700],
                    }}
                />
                <CardContent>
                    <Box
                        sx={{
                            ...centered_flex_box,
                            flexDirection: "column"
                        }}
                    >
                        {!event.expired && <ImageComponent src={event.cover} width="100%" />}
                        <Button sx={{ ...main_button, borderRadius: 0 }} onClick={() => navigate(`/user/events/guestbook/${event._id}`)} fullWidth >
                            <MenuBook sx={{ marginX: 1 }} /> View Guestbook
                        </Button>
                    </Box>
                </CardContent>
                {!event?.expired && <><CardContent>
                    <Typography fontWeight="light">Guests stop uploading {moment(event.date).add(event.bundle.uploadPeriod, "days").fromNow()}</Typography>
                    <Typography fontWeight="light">Event expires {moment(event.date).add(event.bundle.expiry, "months").fromNow()}</Typography>
                </CardContent>
                <CardActions>
                    <Button sx={{ ...main_button }} onClick={() => navigate(`/user/events/${event._id}`)} fullWidth >
                        <Visibility sx={{ marginX: 1 }} /> View Event
                    </Button>
                    <Button sx={{ ...main_button }} onClick={() => navigate(`/user/events/edit/${event._id}`)} fullWidth >
                        <Edit sx={{ marginX: 1 }} /> Edit Event
                    </Button>
                </CardActions></>}
            </Card>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EventCard);