import * as React from 'react';
import Logo from "../../app/components/logo.png"
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, InputAdornment, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, SecInput, sec_button, ter_button } from '../../app/components/Styles';
import { Image, notification } from 'antd';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { getEvent, joinEvent } from '../../app/store/actions/guestActions';
import { isMobile } from 'react-device-detect';
import { Album, Book, Collections, MenuBook, Mic, Notifications, Upload } from '@mui/icons-material';
import { useState } from 'react';
import MessageModal from '../../app/components/MessageModal';
import ReminderModal from '../../app/components/ReminderModal';
import ImageComponent from '../../app/components/ImageComponent';

export const EventHome = ({ joinEvent, user, authToken, isLoading, event, getEvent }) => {

    const { eventId } = useParams()
    const[messageModal, setMessageModal] = useState(false);
    const[reminderModal, setReminderModal] = useState(false);
    const navigate = useNavigate()

    React.useEffect(() => {
        getEvent({ eventId })
    }, [eventId]);

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Container component="main" maxWidth="xs">
            <MessageModal eventId={eventId} open={messageModal} handleClose={() => setMessageModal(false)}/>
            <ReminderModal eventId={eventId} open={reminderModal} handleClose={() => setReminderModal(false)}/>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                {event?.cover && <ImageComponent src={event?.cover} width={isMobile ? "100vw" : "auto"} height={isMobile ? "auto" : "30vh"} preview={true} />}
                <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 1 }}>
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        {event?.name}
                    </Typography>
                </Container>
                <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100%" }}>
                    <Button sx={{ ...main_button, my: 1 }} fullWidth onClick={() => navigate(`/guest/event/upload/${eventId}`)}><Upload sx={{ mx: 1 }} /> Upload Media</Button>
                    {event?.bundle?.voiceMessage && <Button sx={{ ...main_button, my: 1 }} fullWidth onClick={() => navigate(`/guest/event/audio/${eventId}`)}><Mic sx={{ mx: 1 }} /> Create Voice Note</Button>}
                    <Button sx={{ ...main_button, my: 1 }} fullWidth onClick={() => setMessageModal(true)}><MenuBook sx={{ mx: 1 }} /> {event?.bundle.name === "Wedding" ? "Add Guestbook Message" : "Add Message to Hosts"}</Button>
                    {event?.private && <Button sx={{ ...main_button, my: 1 }} onClick={() => navigate(`/guest/event/gallery/${eventId}`)} fullWidth><Collections sx={{ mx: 1 }} /> View My Uploads</Button>}
                    {!event?.private && <Button sx={{ ...main_button, my: 1 }} onClick={() => navigate(`/guest/event/gallery/${eventId}`)} fullWidth><Collections sx={{ mx: 1 }} /> View Gallery</Button>}
                    <Button sx={{ ...ter_button, my: 1 }} fullWidth onClick={() => setReminderModal(true)}><Notifications sx={{ mx: 1 }} /> Remind Me Later</Button>
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    event: state?.records?.event,
});

const mapDispatchToProps = { getEvent, joinEvent };

export default connect(mapStateToProps, mapDispatchToProps)(EventHome);