import React, { useEffect, useState } from "react";
import "./App.css";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import TopBar from "../src/app/components/TopBar";
import BackToTop from 'react-back-to-top';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { BottomBar } from "./app/components";
import ScrollToTop from "./app/components/RouteToTop";
import ReactGA from 'react-ga4';
import PromocodeDialog from "./app/components/PromocodeDialog";
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING);

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
      secondary: "#8F7054"
    },
    secondary: {
      main: '#8F7054'
    },
    white: {
      main: "#ffffff"
    },
    green: {
      main: "green"
    },
    red: {
      main: "red"
    },
    gray: {
      main: "gray"
    },
    blue: {
      main: "blue"
    },
    yellow: {
      main: "yellow"
    },
  }
});


function App() {

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('visited_povpix')) {
      localStorage.setItem('visited_povpix', 'true');
      setOpen(true)
    }
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_ID}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <BrowserRouter>
            {!window.location.pathname.includes("embed") && <TopBar />}
            <div className="fullPage">
              <Router />
              <ScrollToTop />
              <BackToTop
                mainStyle={{
                  "border-radius": 30,
                  "background-color": "var(--secColor)"
                }}
                children={<KeyboardArrowUpIcon sx={{ color: "var(--appBg)" }} />}
                isPercent={false}
                offsetTop={0}
                step={50} />
            </div>
            {!window.location.pathname.includes("embed") && <BottomBar />}
          </BrowserRouter>
          {/* <PromocodeDialog open={open} handleClose={() => setOpen(false)} /> */}
        </div>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
