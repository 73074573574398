import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { redeemVoucher } from '../../app/store/actions/usersActions';
import { Navigate, NavLink, Route, Routes, useNavigate } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput } from '../../app/components/Styles';
import { isMobile } from 'react-device-detect';
import ImageComponent from '../../app/components/ImageComponent';
import Logo from "../../app/components/logo.png"

export const RedeemVoucher = ({ redeemVoucher, user, token, isLoading }) => {

    const navigate = useNavigate()

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var details = {
            code: data.get('code')
        }
        redeemVoucher(details, navigate)
    };

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, minHeight: isMobile ? "100%":"100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Container component="main">
            <Box
                sx={{
                    paddingTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <ImageComponent preview={false} src={Logo} height={160} width={160} />
                <Typography component="h1" variant="h5">Redeem Voucher</Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <MainInput
                        margin="normal"
                        required
                        fullWidth
                        id="code"
                        label="Voucher Code"
                        name="code"
                        type="number"
                        autoFocus
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, ...main_button }}
                    >
                        {isLoading ? <CircularProgress sx={{ color: "white" }} /> : "Redeem"}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading,
    token: state?.auth?.token
});

const mapDispatchToProps = { redeemVoucher };

export default connect(mapStateToProps, mapDispatchToProps)(RedeemVoucher);