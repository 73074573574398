import * as React from 'react';
import { Typography, Button, Grid, IconButton, CardContent, CardActionArea, CardActions, CardHeader } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Card } from '@mui/material';
import { centered_flex_box, left_flex_box, main_button } from './Styles';
import { AiFillPlusCircle } from "react-icons/ai";
import { AiFillMinusCircle } from "react-icons/ai"
import { isMobile } from 'react-device-detect';

function FAQCard({ question, answer, buttonData }) {
    const [expanded, setExpanded] = React.useState(false)
    const navigate = useNavigate()

    return (
        <Card sx={{ width: "100%" }}>
            <CardHeader
                title={question}
                titleTypographyProps={{ textAlign: 'center' }}
                action={<IconButton onClick={() => setExpanded(!expanded)}>{expanded ? <AiFillMinusCircle size={30} color='var(--secColor)' /> : <AiFillPlusCircle size={30} color='var(--secColor)' />}</IconButton>}
                subheaderTypographyProps={{
                    textAlign: 'center',
                }}
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[700],
                }}
            />
            {expanded && <>
                <CardContent sx={{ backgroundColor: "var(--terColor)", color: "var(--secColor) !important", p: 2 }}>
                    <Typography fontSize="16px" textAlign="justify" color="black">
                        {answer}
                    </Typography>
                    <CardActions>
                        {buttonData && <Button onClick={() => navigate(buttonData.navigate)} sx={{ mt: 1, ...main_button }}>{buttonData.text}</Button>}
                    </CardActions>
                </CardContent>
            </>}
        </Card>
    )
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FAQCard);