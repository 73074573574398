import * as React from "react";
import {
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Box,
    MenuItem,
    InputAdornment,
    IconButton,
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    CircularProgress
} from "@mui/material";
import { connect } from "react-redux";
import { MainInput, centered_flex_box, main_button } from "./Styles";
import { isMobile } from "react-device-detect";
import MuiPhoneNumber from "material-ui-phone-number";
import { useNavigate } from "react-router-dom";
import { sendMessage } from "../store/actions/guestActions";

function BundleInfoModal({ open, handleClose, bundle }) {

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ color: "var(--appBg)", minWidth: "100vw" }}
        >
            <Box
                sx={{
                    ...centered_flex_box,
                    flexDirection: "column",
                    padding: 2
                }}
            >
                <Typography textAlign="center" component="h2" variant="h3" gutterBottom={2} color="text.primary">
                    {bundle?.name} Bundle
                </Typography>
                <ul>
                    <Typography
                        component="li"
                        variant="subtitle1"
                    >
                        Private QR Code & Link With Pin
                    </Typography>
                    <Typography
                        component="li"
                        variant="subtitle1"
                    >
                        Unlimited Photos & Guestbook Messages
                    </Typography>
                    <Typography
                        component="li"
                        variant="subtitle1"
                        sx={{ textDecoration: !bundle?.video ? "line-through" : "" }}
                    >
                        Unlimited Videos
                    </Typography>
                    <Typography
                        component="li"
                        variant="subtitle1"
                        sx={{ textDecoration: !bundle?.voiceMessage ? "line-through" : "" }}
                    >
                        Unlimited Voice Messages
                    </Typography>
                    <Typography
                        component="li"
                        variant="subtitle1"
                    >
                        Up to <strong>{bundle?.maxUsers} Guests</strong>
                    </Typography>
                    <Typography
                        component="li"
                        variant="subtitle1"
                    >
                        Guests Can Keep Uploading for <strong>{bundle?.uploadPeriod} Days</strong>
                    </Typography>
                </ul>
            </Box>
        </Dialog >
    );
}

const mapStateToProps = (state) => ({
    isWaiting: state?.wait?.isLoading
});

const mapDispatchToProps = { sendMessage };

export default connect(mapStateToProps, mapDispatchToProps)(BundleInfoModal);
