import * as React from 'react';
import Logo from "../../app/components/logo.png"
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, InputAdornment, IconButton, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, SecInput } from '../../app/components/Styles';
import { Image, notification } from 'antd';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { getEvent, joinEvent } from '../../app/store/actions/guestActions';
import { isMobile } from 'react-device-detect';
import ImageComponent from '../../app/components/ImageComponent';

export const EventWelcome = ({ joinEvent, user, authToken, isLoading, event, getEvent }) => {

    const { eventId, pin } = useParams()
    const navigate = useNavigate()
    const eventCookie = Cookies.get(`guestId_${eventId}`);

    React.useEffect(() => {
        getEvent({ eventId })

    }, [eventId])

    if (eventCookie !== undefined) {
        navigate(`/guest/event/${eventId}`)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var details = {
            pin: data.get('pin'),
            name: data.get('name'),
            eventId
        }
        if (data.get("cookies") === "on") {
            joinEvent(details, navigate);
        } else {
            notification.error({ message: "Kindly Accept Necessary Cookies", style: { marginTop: "15vh" } })
        }
    };

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                {event?.cover && <ImageComponent style={{ marginTop: -1 }} src={event?.cover} width={isMobile ? "100vw" : "auto"} height={isMobile ? "auto" : "30vh"} preview={true}/>}
                <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 1 }}>
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        {event?.name}
                    </Typography>
                </Container>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <MainInput
                        margin="normal"
                        sx={{ my: 1 }}
                        required
                        fullWidth
                        name="pin"
                        label="Event Pin"
                        type='number'
                        inputProps={{ inputMode: 'numeric', pattern: '^[0-9]{6,6}$' }}
                        id="pin"
                        defaultValue={pin}
                    />
                    <MainInput
                        margin="normal"
                        sx={{ my: 1 }}
                        required
                        fullWidth
                        name="name"
                        label="Guest Name"
                        id="name"
                    />
                    <Tooltip title="Necessary cookies allow us to remember you so you can upload freely">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="secondary"
                                    name="cookies"
                                    id="cookies"
                                    defaultChecked={true}
                                />
                            }
                            label="Accept Necessary Cookies"
                        />
                    </Tooltip>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, ...main_button }}
                    >
                        Join Event
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    event: state?.records?.event,
});

const mapDispatchToProps = { getEvent, joinEvent };

export default connect(mapStateToProps, mapDispatchToProps)(EventWelcome);