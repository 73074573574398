import * as React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Snackbar
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { main_button, sec_button } from "./Styles";
import copy from 'clipboard-copy';

function PromocodeDialog({ open, handleClose }) {

  const [snackBar, setSnackBar] = React.useState(false);

  const handleCopyCode = () => {
    copy("LAUNCH35");
    setSnackBar(true);
  };

  const handleCloseSnackbar = () => {
    setSnackBar(false)
    handleClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ color: "var(--secColor)" }}
    >
      <DialogTitle sx={{ fontWeight: "bold" }} id="alert-dialog-title">
        Welcome to POV Pix!
      </DialogTitle>
      <DialogContent>
        <DialogContentText textAlign="center" color={"var(--secColor)"} id="alert-dialog-description">
          We would like to celebrate our launch by sharing with you this promocode for 5% off your first purchase
        </DialogContentText>
        <DialogContentText textAlign="center" fontSize={30} fontWeight="bold" color={"var(--secColor)"} id="alert-dialog-description">
          LAUNCH5
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ ...main_button }} onClick={handleCopyCode}>
          Copy Promocode
        </Button>
      </DialogActions>
      <Snackbar
        open={snackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Promocode Copied"
      />
    </Dialog>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PromocodeDialog);
