import * as React from "react";
import {
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Box,
    MenuItem,
    InputAdornment,
    IconButton,
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    CircularProgress,
    FormControlLabel,
    Checkbox,
    Snackbar,
    Badge
} from "@mui/material";
import { connect } from "react-redux";
import { MainInput, centered_flex_box, main_button } from "./Styles";
import { isMobile } from "react-device-detect";
import MuiPhoneNumber from "material-ui-phone-number";
import { useNavigate } from "react-router-dom";
import { sendMessage } from "../store/actions/guestActions";
import ShareButton from "./ShareButton";
import { useState } from "react";
import copy from 'clipboard-copy';
import { useRef } from "react";
import html2canvas from 'html2canvas';
import { Download } from "@mui/icons-material";
import { QRCode } from "antd";

function EventInfoModal({ open, handleClose, event }) {
    const [withPin, setWithPin] = useState(true);
    const [snackBar, setSnackBar] = useState(false);
    const qrCodeRef = useRef(null);

    const downloadQRCode = () => {
        // const canvas = document.getElementById('myqrcode')?.querySelector('canvas');
        // if (canvas) {
        //     const url = canvas.toDataURL();
        //     const a = document.createElement('a');
        //     a.download = 'QRCode.png';
        //     a.href = url;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        // }
        const qrCodeElement = document.getElementById('myqrcode');
        html2canvas(qrCodeElement).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = `${event?.name}-QRcode.png`;
            link.click();
        });
    };

    const handleCopyLink = () => {
        const currentURL = `https://www.pov-pix.com/guest/event/welcome/${event?._id}${withPin ? `/${event?.pin}` : ""}`;
        copy(currentURL);
        setSnackBar(true);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ color: "var(--appBg)", minWidth: "100vw" }}
        >
            <Box
                sx={{
                    padding: 2
                }}
            >
                <Typography textAlign="center" component="h2" variant="h3" gutterBottom={2} color="text.primary">
                    Share Event Link
                </Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="secondary"
                            checked={withPin}
                            onChange={() => setWithPin(!withPin)}
                            name="withPin"
                            id="withPin"
                        />
                    }
                    label="Link with Pin"
                />
                <Box id="myqrcode" ref={qrCodeRef} sx={{ ...centered_flex_box, my: 2 }}>
                    <QRCode
                        size={255}
                        errorLevel="H"
                        value={`https://www.pov-pix.com/guest/event/welcome/${event?._id}${withPin ? `/${event?.pin}` : ""}`}
                        iconSize={75}
                    />
                </Box>
                <Typography variant="h5" textAlign="center" gutterBottom={2} color="text.primary">
                    PIN: {event?.pin}
                </Typography>
                {isMobile && <Typography gutterBottom={2} color="text.primary">
                    Press on the QR Code and click save for the best quality
                </Typography>}
                <Box>
                    <Button onClick={handleCopyLink} sx={{ ...main_button }}>
                        Copy Link
                    </Button>
                    {!isMobile && <IconButton onClick={downloadQRCode}>
                        <Download />
                    </IconButton>}
                    <ShareButton title={event?.name} url={`https://www.pov-pix.com/guest/event/welcome/${event?._id}${withPin ? `/${event?.pin}` : ""}`} />
                </Box>
                <Snackbar
                    open={snackBar}
                    autoHideDuration={3000}
                    onClose={() => setSnackBar(false)}
                    message="Link Copied"
                />
            </Box>
        </Dialog >
    );
}

const mapStateToProps = (state) => ({
    isWaiting: state?.wait?.isLoading
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EventInfoModal);
