import * as React from "react";
import {
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Box,
    MenuItem,
    InputAdornment,
    IconButton,
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    CircularProgress
} from "@mui/material";
import { connect } from "react-redux";
import { MainInput, centered_flex_box, main_button } from "./Styles";
import { isMobile } from "react-device-detect";
import MuiPhoneNumber from "material-ui-phone-number";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Check } from "@mui/icons-material";
import { sendRequest } from "../store/actions/usersActions";

function RequestModal({ open, handleClose, event, isLoading, isWaiting, sendRequest }) {

    const navigate = useNavigate();

    const handleSubmit = (ev) => {
        ev.preventDefault();
        const data = new FormData(ev.currentTarget);
        const requestInfo = {
            eventId: event?._id,
            type: data.get('type'),
            contact: data.get('contact'),
            info: data.get('info')
        }

        sendRequest(requestInfo, handleClose)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ color: "var(--appBg)", minWidth: "100vw" }}
        >
            <Box component="form" onSubmit={handleSubmit} sx={{ padding: 2 }}>
                <Typography variant="h4">Request Printing for "{event?.name}"</Typography>
                <MainInput
                    margin="normal"
                    required
                    fullWidth
                    id="type"
                    label="What to print?"
                    name="type"
                    autoFocus
                    select
                    defaultValue={"Placements"}
                >
                    <MenuItem value={"Placements"}>
                        <Typography>Table Placements</Typography>
                    </MenuItem>
                    {/* <MenuItem value={"Guestbook"}>
                        <Typography>Physical Guestbook</Typography>
                    </MenuItem> */}
                </MainInput>
                <hr />
                <MuiPhoneNumber
                    sx={{
                        "& label": {
                            color: "var(--secColor) !important",
                        },
                        "& label.Mui-focused": {
                            color: "var(--secColor) !important",
                        },
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                border: "1px solid var(--secColor)",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "var(--terColor)",
                            },

                            borderRadius: 0.5,
                        },
                    }}
                    margin="normal"
                    fullWidth
                    required
                    id="contact"
                    label="Contact Mobile Number"
                    name="contact"
                    variant='outlined'
                    defaultCountry={'eg'}
                    preferredCountries={['eg']}
                    countryCodeEditable={false}
                    inputProps={{
                        minLength: 13
                    }}
                    disableAreaCodes
                />
                <MainInput
                    margin="normal"
                    fullWidth
                    multiline
                    minRows={3}
                    name="info"
                    label="Additional Information"
                    id="info"
                />
                <hr />
                <Typography gutterBottom={2} color={"text.secondary"}>Kindly note that this service is only available in Egypt at the moment</Typography>
                <Button type='submit' sx={{ ...main_button }} disabled={isWaiting} fullWidth>{isWaiting ? <CircularProgress sx={{ color: "var(--secColor)" }} /> : "Send Request"}</Button>
            </Box>
        </Dialog >
    );
}

const mapStateToProps = (state) => ({
    isLoading: state?.records?.isLoading,
    isError: state?.records?.isError,
    isWaiting: state?.wait?.isLoading
});

const mapDispatchToProps = { sendRequest };

export default connect(mapStateToProps, mapDispatchToProps)(RequestModal);
