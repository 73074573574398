import * as React from "react";
import {
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Box,
    MenuItem,
    InputAdornment,
    IconButton,
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    CircularProgress,
    FormControlLabel,
    Checkbox,
    Tooltip
} from "@mui/material";
import { connect } from "react-redux";
import { MainInput, centered_flex_box, main_button } from "./Styles";
import { isMobile } from "react-device-detect";
import MuiPhoneNumber from "material-ui-phone-number";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Check } from "@mui/icons-material";
import { checkout, checkPromo } from "../store/actions/usersActions";

function CheckoutModal({ open, handleClose, bundle, checkout, checkPromo, promo, isLoading, isWaiting, isError, abroad, price }) {

    const navigate = useNavigate();

    const [subtotal, setSubtotal] = useState(0)
    const [fees, setFees] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [additional, setAdditional] = useState(0)
    const [total, setTotal] = useState(0)
    const [designs, setDesigns] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState("Card")
    const [promoCode, setPromoCode] = useState("")

    useEffect(() => {
        if (bundle) {
            setSubtotal(price)
        }
    }, [bundle])

    useEffect(() => {
        if (paymentMethod === "ValU") {
            setFees(((subtotal + additional - discount) * 0.04 + 5) * 1.14)
        } else {
            setFees(0)
        }
    }, [paymentMethod, subtotal, discount, additional])

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const orderInfo = {
            bundleId: bundle?._id,
            paymentMethod,
            mobile: data.get('mobile'),
            promoCode,
            designs,
            abroad
        }
        checkout(orderInfo, navigate)
    }

    const checkPromocode = (event) => {
        event.preventDefault();
        checkPromo({ promoCode, orderPrice: subtotal })
    }

    useEffect(() => {
        if (promo) {
            setDiscount(subtotal * promo?.discount)
        } else {
            setDiscount(0)
        }
    }, [promo])

    useEffect(() => {
        if (designs) {
            setAdditional(350)
        } else {
            setAdditional(0)
        }
    }, [designs])

    useEffect(() => {
        setTotal(subtotal + fees + additional - discount)
    }, [subtotal, fees, additional, discount])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ color: "var(--appBg)", minWidth: "100vw" }}
        >
            <Box component="form" onSubmit={handleSubmit} sx={{ padding: 2 }}>
                <Typography variant="h4">Checkout {bundle?.name} Bundle</Typography>
                <MainInput
                    margin="normal"
                    required
                    fullWidth
                    id="paymentMethod"
                    label="Payment Method"
                    name="paymentMethod"
                    autoFocus
                    select
                    value={paymentMethod}
                    onChange={({ target }) => setPaymentMethod(target.value)}
                >
                    <MenuItem value={"Card"}>
                        <Typography>Credit / Debit Card</Typography>
                    </MenuItem>
                    {!abroad && <MenuItem value={"Mobile"}>
                        <Typography>Mobile Wallet</Typography>
                    </MenuItem>}
                    {!abroad && subtotal >= 500 && <MenuItem value={"ValU"}>
                        <Typography>ValU Installments</Typography>
                    </MenuItem>}
                    {/* {abroad && <MenuItem value={"Paypal"}>
                        <Typography>PayPal</Typography>
                    </MenuItem>} */}
                </MainInput>
                {paymentMethod === "Mobile" && <MuiPhoneNumber
                    sx={{
                        "& label": {
                            color: "var(--secColor) !important",
                        },
                        "& label.Mui-focused": {
                            color: "var(--secColor) !important",
                        },
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                border: "1px solid var(--secColor)",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "var(--terColor)",
                            },

                            borderRadius: 0.5,
                        },
                    }}
                    margin="normal"
                    fullWidth
                    required
                    id="mobile"
                    label="Mobile Number"
                    name="mobile"
                    variant='outlined'
                    defaultCountry={'eg'}
                    preferredCountries={['eg']}
                    countryCodeEditable={false}
                    inputProps={{
                        minLength: 13
                    }}
                    disableAreaCodes
                />
                }
                <Box sx={{mt: 1, display: "flex", flexDirection: "column"}}>
                    <Tooltip title="Check if you want to order placement designs for your event">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="secondary"
                                    value={designs}
                                    onChange={() => setDesigns(!designs)}
                                    name="designs"
                                    id="designs"
                                />
                            }
                            label="Add Design Service (350 EGP)"
                        />
                    </Tooltip>
                    <Typography
                        variant="p"
                        color="text.secondary"
                        textAlign="justify"
                        gutterBottom
                    >
                        Our designers will create 2 designs based on your requests for the table placements / cards
                    </Typography>
                    <br />
                </Box>
                <hr />
                <FormControl sx={{ width: "100%" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "var(--secColor) !important" }}>
                        Promo Code
                    </InputLabel>
                    <OutlinedInput
                        sx={{
                            "& fieldset": {
                                border: "1px solid var(--secColor)  !important",
                            },
                            "&.Mui-focused fieldset": {
                                border: "2px solid var(--secColor)  !important",
                            },
                            borderRadius: 0.5
                        }}
                        id="promoCode"
                        name="promoCode"
                        fullWidth
                        value={promoCode}
                        onChange={({ target }) => setPromoCode(target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <Button sx={{ ...main_button }} onClick={checkPromocode} disabled={isLoading} fullWidth>{isLoading ? <CircularProgress sx={{ color: "var(--secColor)" }} /> : "Apply"}</Button>
                            </InputAdornment>
                        }
                        label={"Promo Code"}
                    />
                </FormControl>
                <br />  
                {(promo && <Typography fontSize={isMobile ? 14 : 18} sx={{ color: "var(--secColor)" }}>Promocode Applied</Typography>) || (!promo && isError && <Typography fontSize={isMobile ? 14 : 18} sx={{ color: "maroon" }}>Invalid Promocode</Typography>)}
                <hr />
                <Typography fontSize={isMobile ? 12 : 20}>Bundle Price: {(subtotal).toFixed(2)} EGP</Typography>
                <Typography fontSize={isMobile ? 12 : 20}>Services: {(additional).toFixed(2)} EGP</Typography>
                {fees > 0 && <Typography fontSize={isMobile ? 12 : 20}>Fees: {(fees).toFixed(2)} EGP</Typography>}
                <Typography fontSize={isMobile ? 12 : 20}>Discount: {(discount).toFixed(2)} EGP</Typography>
                <Typography fontWeight="bold" fontSize={isMobile ? 18 : 25}>Total: {(total).toFixed(2)} EGP</Typography>
                <hr />
                <Button type='submit' sx={{ ...main_button }} disabled={isWaiting} fullWidth>{isWaiting ? <CircularProgress sx={{ color: "var(--secColor)" }} /> : "Checkout"}</Button>
            </Box>
        </Dialog >
    );
}

const mapStateToProps = (state) => ({
    promo: state?.records?.promo,
    isLoading: state?.records?.isLoading,
    isError: state?.records?.isError,
    isWaiting: state?.wait?.isLoading
});

const mapDispatchToProps = { checkout, checkPromo };

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutModal);
