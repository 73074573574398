import { Celebration, Delete, Diamond, Download, Email, ExpandMore, Facebook, Message, MoreHoriz, MusicNote, Phone, Send, Star, StarOutline, Upload, WhatsApp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Chip, CircularProgress, Dialog, Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { MainInput, centered_flex_box, main_button, reject_button, right_flex_box, sec_button } from "./Styles";
import { isMobile } from "react-device-detect";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Image, notification } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { deleteFile, editFile, starFile } from "../store/actions/usersActions";
import { deleteFile as guestDelete, editFile as guestEdit } from "../store/actions/guestActions";
import moment from "moment";
import { uploadFile, deleteObject } from "../../core/aws";
import { config } from "../config";
import ImageComponent from "./ImageComponent";
import download from "downloadjs";

function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
}

function GalleryCard({ user, file, eventId, event, editFile, deleteFile, guestEdit, guestDelete, action, setAction, guest, cookieId, starFile }) {

    const [addCaption, setAddCaption] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (ev) => {
        setAnchorEl(ev.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const guestCookie = Cookies.get(`guestId_${eventId}`) || cookieId;
    console.log(guestCookie)

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const fileInfo = {
            eventId,
            uploadId: file._id,
            caption: data.get('caption')
        }
        setAddCaption(false)
        if (guest) {
            guestEdit(fileInfo)
        } else {
            editFile(fileInfo)
        }
        timeout(100)
        setAction(!action)
    }

    const handleDelete = async (event) => {
        event.preventDefault();
        if (guest) {
            await guestDelete({ uploadIds: [file._id], eventId }, file)
        } else {
            await deleteFile({ uploadIds: [file._id], eventId }, file)
        }
        timeout(100)
        setAction(!action)
    }

    const handleStar = async (event) => {
        event.preventDefault();
        starFile({ eventId, uploadId: file._id, star: file.starred ? false : true })
        timeout(200)
        setAction(!action)
    }

    return (
        <Grid item xs={isMobile ? 6 : 2}>
            <Card sx={{ height: (addCaption ? 100 : 0) + (isMobile ? 350 : 500), display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <CardContent sx={{ ...centered_flex_box, flexDirection: "column", padding: "8px !important" }}>
                    <Box sx={{...right_flex_box, justifyContent: "space-between", width: "100%"}}>
                        <Box>
                            {user?._id === event?.user && (
                                <IconButton onClick={handleStar}>{file.starred ? <Star sx={{color: "var(--secColor)"}}/> : <StarOutline />}</IconButton>
                            )}
                            { user?._id !== event?.user && file.starred && <Star sx={{color: "var(--secColor)"}}/>}
                        </Box>
                        <Box>
                            { ((guest && file.guest?._id === guestCookie) || (user?._id === event?.user)) && <IconButton onClick={handleClick}><MoreHoriz /></IconButton>}
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                {guest && file.guest?._id === guestCookie && (
                                    <MenuItem sx={{color: "var(--secColor)"}} onClick={handleDelete}><Delete /><Typography>Delete File</Typography></MenuItem>
                                )}
                                {user?._id === event?.user && (
                                    <MenuItem sx={{color: "var(--secColor)"}} onClick={handleDelete}><Delete /><Typography>Delete File</Typography></MenuItem>
                                )}
                                {(user?._id === event?.user || event?.allowDownloads) && (
                                    <MenuItem sx={{color: "var(--secColor)"}} onClick={() => download(file.link)}><Download /><Typography>Download File</Typography></MenuItem>
                                )}
                                {guest && file.guest?._id === guestCookie && !addCaption && !file.caption && (
                                    <MenuItem onClick={() => setAddCaption(true)} sx={{color: "var(--secColor)"}}><Message /><Typography>Add Caption</Typography></MenuItem>
                                )}
                                {user?._id === event?.user && !file.caption && (
                                    <MenuItem onClick={() => setAddCaption(true)} sx={{color: "var(--secColor)"}}><Message /><Typography>Add Caption</Typography></MenuItem>
                                )}
                                
                            </Menu>
                        </Box>
                    </Box>
                    {file.type === "Image" && <Image src={file.link} alt={file.caption} width={isMobile ? 150 : 300} height={isMobile ? 150 : 300} preview={true} />}
                    {file.type === "Video" && <video autoPlay={false} controls src={file.link} alt={file.caption} style={{ width: isMobile ? 150 : 300, height: isMobile ? 150 : 300 }} />}
                    {file.type === "Audio" && <Box sx={{ width: isMobile ? 150 : 300, height: isMobile ? 150 : 300, ...centered_flex_box, flexDirection: "column" }}>
                        <MusicNote sx={{ width: isMobile ? 150 : 300, height: isMobile ? 110 : 260 }} />
                        <audio autoPlay={false} controls src={file.link} alt={file.caption} style={{ width: isMobile ? 150 : 300, height: 40 }} />
                    </Box>}
                </CardContent>
                <CardContent>
                    {(guest && file.guest?._id === guestCookie &&
                        (file.caption !== "" ? <>
                            <Typography fontStyle="italic">"{file.caption}"</Typography>
                        </> : <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                            {addCaption && <Box component="form" onSubmit={handleSubmit}>
                                <MainInput
                                    margin="normal"
                                    fullWidth
                                    multiline
                                    required
                                    minRows={2}
                                    inputProps={{
                                        maxlength: 144,
                                    }}
                                    name={"caption"}
                                    label="Caption"
                                    id={"caption"}
                                />
                                <Box sx={{ ...centered_flex_box }}>
                                    <Button type="submit" sx={{ ...sec_button, mr: 1 }}>Submit</Button>
                                    <Button sx={{ ...sec_button }} onClick={() => setAddCaption(false)} >Cancel</Button>
                                </Box>
                            </Box>}
                        </Box>))}
                    {(!guest &&
                        (file.caption !== "" ? <>
                            <Typography fontStyle="italic">"{file.caption}"</Typography>
                        </> : <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                            {addCaption && <Box component="form" onSubmit={handleSubmit}>
                                <MainInput
                                    margin="normal"
                                    fullWidth
                                    multiline
                                    required
                                    minRows={2}
                                    inputProps={{
                                        maxlength: 144,
                                    }}
                                    name={"caption"}
                                    label="Caption"
                                    id={"caption"}
                                />
                                <Box sx={{ ...centered_flex_box }}>
                                    <Button type="submit" sx={{ ...sec_button, mr: 1 }}>Submit</Button>
                                    <Button sx={{ ...sec_button }} onClick={() => setAddCaption(false)} >Cancel</Button>
                                </Box>
                            </Box>}
                        </Box>))}
                    <Typography>~{file.guest?.name || file.user?.name || "You"}</Typography>
                </CardContent>
                <CardContent sx={{padding: "5px !important"}}>
                    {file?.folder && <Typography>{file.folder.name}</Typography>}
                    <Typography fontWeight="light" fontSize={12} color={"text.secodary"} fontStyle={"italic"}>{moment(file.createdAt).format("DD/MM/YYYY HH:mm")}</Typography>
                </CardContent>
                {/* <CardActions sx={{ display: 'flex', justifyContent: "center" }}>
                    {guest && file.guest?._id === guestCookie && <>
                        <IconButton onClick={handleDelete}><Delete /></IconButton>
                    </>}
                    {!guest && <>
                        <IconButton onClick={handleDelete}><Delete /></IconButton>
                    </>}
                    {(!guest || event?.allowDownloads) && <IconButton onClick={() => download(file.link)}><Download /></IconButton>}
                </CardActions> */}
            </Card>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
});

const mapDispatchToProps = { deleteFile, editFile, guestDelete, guestEdit, starFile };

export default connect(mapStateToProps, mapDispatchToProps)(GalleryCard);