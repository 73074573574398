import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
    Typography,
    Box,
    Container,
    TextField,
    CssBaseline,
    Button,
    Avatar,
    Select,
    MenuItem,
    InputLabel,
    FormHelperText,
    FormControl,
    CircularProgress,
    Chip,
    Tooltip,
    Card,
} from "@mui/material";
import { connect } from "react-redux";
import OutboxIcon from "@mui/icons-material/Outbox";
import { uploadFile } from "react-s3";
import {
    centered_flex_box,
    MainInput,
    MainInputLabel,
    MainTextArea,
    main_button,
    reject_button,
    sec_button,
    StyledInput,
} from "../../app/components/Styles";
import { useNavigate, useParams } from "react-router-dom";
import ReactFileReader from "react-file-reader";
import InfoCard from "../../app/components/InfoCard";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import ImageComponent from "../../app/components/ImageComponent";
import FAQCard from "../../app/components/FAQCard";

export const FAQs = ({ }) => {
    const navigate = useNavigate();

    return (
        <Container>
            <Box sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}>
                <Typography
                    fontSize={30}
                    fontWeight="bold"
                    textAlign="center"
                    color={"var(--secColor)"}
                >
                    FAQs
                </Typography>
                <hr
                    style={{
                        width: "10%",
                        opacity: "100%",
                        "border-top": "solid var(--secColor) 3px",
                    }}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <FAQCard question={"How do I create an event?"} answer={"It is simple, find a bundle that suits you and once your order is confirmed we will send you the link to create your event"} buttonData={{ navigate: "/pricing", text: "View Bundles" }} />
                    </Grid>
                    <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <FAQCard question={"I purchased a bundle and didn't receive an email"} answer={"We are sorry to hear that. That could either be because of a failed payment or a mailing issue, however you can still view all orders you've made on the website"} buttonData={{ navigate: "/user/orders", text: "View Completed Orders" }} />
                    </Grid>
                    <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <FAQCard question={"I purchased a physical voucher, what do I do next?"} answer={"Thank you for your purchase! You should redeem the voucher online using the 8 digit code. After it is successfully redeemed, you should be redirected to create your event. If you aren't ready to create the event yet, you can skip and create the event later from the \"My Orders\" page."} buttonData={{ navigate: "/user/voucher", text: "Redeem Voucher" }} />
                    </Grid>
                    <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <FAQCard question={"How long do you store my data?"} answer={"We only store your data for as long as the bundle duration (6 months), after which we permanently delete your event photos from our servers"} buttonData={{ navigate: "/policy/privacy", text: "View Privacy Policy" }} />
                    </Grid>
                    <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <FAQCard question={"Who can see the images my guests and I upload?"} answer={"Only you can view what is uploaded to the event gallery if you created an event with a private gallery, otherwise guests as well can see the gallery"}/>
                    </Grid>
                    <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <FAQCard question={"Do guests need to sign up?"} answer={"Not at all, your guests only need to provide their names when joining your event for the best user experience and they can share all the photos, videos and voice recordings from your event, depending on the bundle purchased"}/>
                    </Grid>
                    <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <FAQCard question={"I have another problem"} answer={"We are sorry that the FAQ section couldn't help, feel free to contact us with your questions / issues"} buttonData={{ navigate: "/contact", text: "Contact Us" }}/>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FAQs);
