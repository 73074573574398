import { CancelOutlined, Celebration, Check, CheckCircle, CheckCircleOutline, Close, Diamond } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Container, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { connect } from "react-redux";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getBundles } from '../../app/store/actions/commonActions';
import { centered_flex_box, main_button } from '../../app/components/Styles';
import { isMobile } from 'react-device-detect';
import { CheckoutModal } from '../../app/components';
import BundleCard from '../../app/components/BundleCard';
import axios from 'axios';
import { Space, Table } from 'antd';

export const Package = ({ route, bundle, getBundles, isLoading, user }) => {

    const { bundleId } = useParams();
    const navigate = useNavigate();
    const [abroad, setAbroad] = React.useState(false)
    const [checkout, setCheckout] = React.useState({
        isOpen: false,
    })

    const { isOpen } = checkout

    const openCheckout = (selectedBundle) => {
        if (user) {
            setCheckout({
                isOpen: true,
            })
        } else {
            navigate("/login")
        }
    }

    const closeCheckout = () => {
        setCheckout({
            isOpen: false
        })
    }

    useEffect(() => {
        console.log(bundleId)
        getBundles({ bundleId })
        axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_GELOC_KEY}`).then((res) => {
            setAbroad(res.data.country_code2 !== "EG")
        });
    }, [bundleId]);

    if (isLoading && !bundle) {
        return (
            <Box sx={{ ...centered_flex_box, height: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <>
            <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    {bundle?.name} Bundle
                </Typography>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main" sx={{ mb: 5 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                    }}
                >
                    <Typography component="h2" variant="h3" color="text.primary">
                        {abroad ? bundle?.priceExt : bundle?.price}
                    </Typography>
                    <Typography variant="h4" color="text.primary">
                        {" "}EGP
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                        /event
                    </Typography>
                </Box>
                {abroad && <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                    }}
                >
                    <Typography variant="h6" color="text.secondary">
                        ~ {(bundle?.priceExt / 50).toFixed(2)} USD
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                        Purchase will be completed in Egyptian Pounds
                    </Typography>
                </Box>}
                <ul>
                    <Typography
                        component="li"
                        variant="subtitle1"
                    >
                        Private QR Code & Link With Pin
                    </Typography>
                    <Typography
                        component="li"
                        variant="subtitle1"
                    >
                        Unlimited Photos & Guestbook Messages
                    </Typography>
                    {bundle?.video && <Typography
                        component="li"
                        variant="subtitle1"
                    >
                        Unlimited Videos
                    </Typography>}
                    {bundle?.voiceMessage && <Typography
                        component="li"
                        variant="subtitle1"
                    >
                        Unlimited Voice Messages
                    </Typography>}
                    <Typography
                        component="li"
                        variant="subtitle1"
                    >
                        Guests Can Keep Uploading for {bundle?.uploadPeriod} Days
                    </Typography>
                    <Typography
                        component="li"
                        variant="subtitle1"
                    >
                        We Keep Your Media Secure for <strong>6 months</strong>
                    </Typography>
                </ul>
                <Button sx={{ ...main_button }} onClick={openCheckout} fullWidth >
                    Buy Now
                </Button>
            </Container >
            <CheckoutModal bundle={bundle} open={isOpen} handleClose={closeCheckout} abroad={abroad} price={abroad ? bundle?.priceExt : bundle?.price} />
        </>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading,
    bundle: state?.records?.bundle
});

const mapDispatchToProps = { getBundles };

export default connect(mapStateToProps, mapDispatchToProps)(Package);