import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Dialog, Card, CardContent, Divider } from '@mui/material';
import { connect } from "react-redux";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainInput, centered_flex_box, left_flex_box, main_button, right_flex_box, sec_button } from '../../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { Image } from 'antd';
import { useEffect } from 'react';
import { ChangeCircleOutlined, Map, MoreHoriz, Visibility } from '@mui/icons-material';
import { getOrders } from '../../app/store/actions/usersActions';

export const SingleOrder = ({ getOrders, isLoading, order }) => {

    const navigate = useNavigate()
    const orderId = useParams().orderId
    const { t, i18n } = useTranslation();
    const [detailsOpen, setDetailsOpen] = React.useState(false)

    useEffect(() => {
        getOrders({ orderId })
    }, [])

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, height: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Box sx={{ paddingX: isMobile ? 2 : 10 }}>
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <Box sx={{ padding: isMobile ? 2 : 10, minWidth: "100vw", minHeight: isMobile ? "20vh" : "40vh", mt: -2, backgroundImage: "url(https://integraecommerce.s3.eu-west-3.amazonaws.com/logos/background.jpg)", backgroundSize: 'cover', backgroundPosition: 'center', position: "relative" }}>
                    <Typography textAlign="center" fontWeight="bold">{t("order.number")}: {order?.paymobOrderId}</Typography>
                    <Typography textAlign="center" fontWeight="bold">{t("order.type")}: {t(`order.${order?.type?.toLowerCase()}`)}</Typography>
                </Box>
            </Box>
            {order?.type === "Quotation" ? (
                <Box sx={{ mt: 2 }}>
                    <Dialog open={detailsOpen} onClose={() => setDetailsOpen(false)}>
                        <Box sx={{ padding: 2 }}>
                            <Typography fontSize={isMobile ? 25 : 30} fontWeight="bold">{t("order.details")}</Typography>
                            <Typography fontSize={isMobile ? 15 : 20} textAlign="justify">{order?.orderDetails}</Typography>
                        </Box>
                    </Dialog>
                    <Typography fontSize={30} fontWeight="bold">{t("order.details")}</Typography>
                    {order?.file && <Button sx={{ ...main_button, mr: 1 }} href={order.file.link} target='_blank'><Visibility sx={{ mr: 1 }} /> View File</Button>}
                    {order?.orderDetails && <Button onClick={() => setDetailsOpen(true)} sx={{ ...main_button, mr: 1 }}><Visibility sx={{ mr: 1 }} /> View Details</Button>}
                    <Grid container spacing={2}>
                        <Grid item xs={isMobile ? 12 : 4}>
                            <Card sx={{ my: 2, padding: 2, ...centered_flex_box }}>
                                <Typography fontWeight="bold" textAlign="center" fontSize={30}>{t(`status.${order?.status}`)}</Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 8}>
                            <Box component="form">
                                <Card sx={{ my: 2, padding: 2 }}>
                                    {order?.items?.length > 0 && order?.items?.map((item) => {
                                        return (
                                            <Card key={item._id} sx={{ my: 2 }}>
                                                <CardContent>
                                                    <Grid container spacing={2} rowSpacing={2}>
                                                        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                                                            <Image src={item.product.image || item.productVariation.image} width={100} height={100} style={{ width: "100", height: "auto" }} />
                                                            <Box sx={{ flexDirection: "column", ml: 2 }}>
                                                                <Typography fontWeight="bold" fontSize={20}>{item.product[`name_ar`]}</Typography>
                                                                <Typography fontWeight="light" fontSize={15}>{item.product.sku}</Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{ ...right_flex_box, flexDirection: "column" }} >
                                                            <Grid container>
                                                                <Grid item xs={6}>
                                                                    <Typography fontWeight="bold" >{item.price === 0 ? t("order.no_price") : `${item.price} ${t("egp")}`}</Typography>
                                                                    <Typography >{item.amount} {t("units")}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        )
                                    })}
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            ) : (<>
                <Box sx={{ mt: 2 }}>
                    <Typography fontSize={30} fontWeight="bold">{t("order.details")}</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={isMobile ? 12 : 4}>
                            <Card sx={{ my: 2, padding: 2 }}>
                                <Typography fontWeight="bold" textAlign="center" fontSize={30}>{t(`status.${order?.status}`)}</Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 8}>
                            <Box component="form">
                                <Card sx={{ my: 2, padding: 2 }}>
                                    {order?.items?.length > 0 && order?.items?.map((item) => {
                                        return (
                                            <Card key={item._id} sx={{ my: 2 }}>
                                                <CardContent>
                                                    <Grid container spacing={2} rowSpacing={2}>
                                                        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                                                            <Image src={item.product.image || item.productVariation.image} width={100} height={100} style={{ width: "100", height: "auto" }} />
                                                            <Box sx={{ flexDirection: "column", ml: 2 }}>
                                                                <Typography fontWeight="bold" fontSize={20}>{item.product[`name_ar`]}</Typography>
                                                                <Typography fontWeight="light" fontSize={15}>{item.product.sku}</Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{ ...right_flex_box, flexDirection: "column" }} >
                                                            <Grid container>
                                                                <Grid item xs={6}>
                                                                    <Typography fontWeight="bold" >{item.price === 0 ? t("order.no_price") : `${item.price} ${t("egp")}`}</Typography>
                                                                    <Typography >{item.amount} {t("units")}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        )
                                    })}
                                </Card>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{ my: 2, padding: 2 }}>
                                <Typography fontWeight="bold" textAlign="center" fontSize={22}>{t("form.address")}</Typography>
                                <Typography fontSize={18}>{t("form.address_line1")}: {order?.address.add1}</Typography>
                                {order?.address.add2 && <Typography fontSize={18}>{t("form.address_line2")}: {order?.address.add2}</Typography>}
                                <Typography fontSize={18}>{t("form.city")}: {order?.address.city}</Typography>
                                <hr />
                                {order?.address.location && <Box>
                                    <Typography fontSize={18}>{t("form.location")}</Typography>
                                    <Button href={order?.address.location} target="_blank" fullWidth sx={{...main_button, marginY: 1}}><Map sx={{marginInlineEnd: 1}}/>{t("form.maps")}</Button>
                                </Box>}
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </>)}
        </Box>
    );
}

const mapStateToProps = (state) => ({
    order: state?.records?.order,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = { getOrders };

export default connect(mapStateToProps, mapDispatchToProps)(SingleOrder);