import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
    Typography,
    Box,
    Container,
    TextField,
    CssBaseline,
    Button,
    Avatar,
    Select,
    MenuItem,
    InputLabel,
    FormHelperText,
    FormControl,
    CircularProgress,
    Chip,
    Tooltip,
    Card,
} from "@mui/material";
import { connect } from "react-redux";
import OutboxIcon from "@mui/icons-material/Outbox";
import { uploadFile } from "react-s3";
import {
    centered_flex_box,
    MainInput,
    MainInputLabel,
    MainTextArea,
    main_button,
    reject_button,
    sec_button,
    StyledInput,
} from "../../app/components/Styles";
import { useNavigate, useParams } from "react-router-dom";
import ReactFileReader from "react-file-reader";
import InfoCard from "../../app/components/InfoCard";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import ImageComponent from "../../app/components/ImageComponent";

export const About = ({ }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation()

    return (
        <Container>
            <Box sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}>
                <Typography
                    fontSize={30}
                    fontWeight="bold"
                    textAlign="center"
                    color={"var(--secColor)"}
                >
                    About Us
                </Typography>
                <hr
                    style={{
                        width: "10%",
                        opacity: "100%",
                        "border-top": "solid var(--secColor) 3px",
                    }}
                />
                <Box sx={{ ...centered_flex_box, flexDirection: "column", mb: 5 }}>
                    {/* <Typography fontSize={20} textAlign="center">
                        Welcome to POV Pix, where memories take center stage in a kaleidoscope of perspectives! Founded on the belief that every event tells a unique story, we've crafted a platform that goes beyond the ordinary. At POV Pix, we empower you to capture, share, and relive moments through a seamless fusion of images, videos, and voice messages. Our vision is to redefine the way you remember and cherish your special occasions. With unlimited storage and tailored bundles, we invite you to turn your events into timeless treasures. Join us in celebrating the beauty of diverse viewpoints and creating a tapestry of memories that lasts a lifetime. Your story, your POV – start your adventure with POV Pix today!
                    </Typography> */}
                    <Typography fontSize={25} mt={5} textAlign="center">
                        📷 Snap, Shoot, Speak – POV Pix, Your Personal Event Lens
                    </Typography>
                </Box>
                <Box sx={{ padding: 2, pt: 0, ...centered_flex_box, flexDirection: "column" }}>
                    <Grid container spacing={isMobile ? 0 : 5}>
                        <Grid item xs={isMobile ? 12 : 4}>
                            <ImageComponent width={"100%"} height={"100%"} preview={false} src="https://pov-pix.s3.eu-west-3.amazonaws.com/Brand/Assets/Table.webp" />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 8} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                            <Typography variant='h3' textAlign="center" gutterBottom={2}>
                                All your memories in one place
                            </Typography>
                            <Typography textAlign="center" gutterBottom={1} fontSize={20}>
                                At POV Pix, we understand that every event is a collage of unique moments, each carrying its own significance.
                            </Typography>
                            <Typography textAlign="center" gutterBottom={1} fontSize={20}>
                                Capture the best moments from your guests in your digital event gallery, without worrying about how you will send or receive them.
                            </Typography>
                            <Typography textAlign="center" gutterBottom={1} fontSize={20}>
                                No matter what event you're hosting, you'll receive guest <strong>photos, videos, voice messages and guestbook messages</strong>.
                            </Typography>
                            <Typography textAlign="center" gutterBottom={1} fontSize={20}>
                                Your media is kept safe online for <strong>6 months</strong> after your event.
                                During which you can download the zip file containing all you media in original resolution.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ minWidth: "100vw", ...(isMobile ? { padding: 5, mb: 2 } : { p: 10 }), ...centered_flex_box, flexDirection: "column", backgroundImage: "url(https://pov-pix.s3.eu-west-3.amazonaws.com/Brand/Assets/BG3.jpg)", backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <Box sx={{ ...centered_flex_box, flexDirection: "column", ...(isMobile ? {} : { paddingX: 20 }) }}>
                        <Typography variant='h2' textAlign="center" gutterBottom={2}>
                            No App, No Fuss
                        </Typography>
                        <Typography fontSize={20} textAlign="center" gutterBottom={2}>
                            With POV Pix there's no need to ask all guests to download an App or register!
                            Your POV Pix QR code / URL is how they add their best photos, videos and guestbook messages. Everyone will instantly enjoy the memories and you can download everything in a click.
                        </Typography>
                        <Typography fontSize={20} fontStyle="italic" gutterBottom={2} textAlign="center">
                            It takes just <strong>2 minutes</strong> from purchase to event creation!
                        </Typography>
                        <Button sx={{ ...main_button }} onClick={() => navigate("/pricing")}>
                            Start Now
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(About);
