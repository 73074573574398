import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Card, Stack, Pagination, CardMedia, CardContent } from '@mui/material';
import { connect } from "react-redux";
import { isMobile } from 'react-device-detect';
import { centered_flex_box, main_button } from '../../app/components/Styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { checkTransaction, getOrders } from '../../app/store/actions/usersActions';
import { CreditCardOff, CreditScore, ErrorOutline } from '@mui/icons-material';
import { notification } from 'antd';

export const CheckTransaction = ({ checkTransaction, getOrders, isSuccessful, isLoading, order }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
        params[key] = value;
    }

    React.useEffect(() => {
        notification.info({message: "Fetching Transaction", style: { marginTop: "10vh" }});
        checkTransaction({ transactionId: params.id })
    }, [])

    React.useEffect(() => {
        if(isSuccessful) {
            notification.success({message: "Transaction Successful!", style: { marginTop: "10vh" }});
            getOrders({ paymobTransactionId: params.order })
        }
    }, [isSuccessful])

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, height: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Box sx={{ paddingX: isMobile ? 2 : 10 }}>
            {isSuccessful ? <>
                <Box sx={{ ...centered_flex_box, height: "100vh", flexDirection: "column", mt: 2 }}>
                    <CreditScore sx={{fontSize: 60}} />
                    <Typography textAlign="center" fontSize={40}>Transaction Successful</Typography>
                    { order && <Button sx={{...main_button}} onClick={() => navigate(`/user/events/create/${order?._id}`)}>Create Your Event</Button>}
                </Box>
            </> : <>
            <Box sx={{ ...centered_flex_box, height: "100vh", flexDirection: "column", mt: 2 }}>
                    <CreditCardOff sx={{fontSize: 60}} />
                    <Typography textAlign="center" fontSize={40}>Transaction Failed</Typography>
                </Box>
            </>}
        </Box>
    );
}

const mapStateToProps = (state) => ({
    isSuccessful: state?.records?.success,
    isLoading: state?.records?.isLoading,
    order: state?.records?.order,
    user: state?.auth?.user
});

const mapDispatchToProps = { checkTransaction, getOrders };

export default connect(mapStateToProps, mapDispatchToProps)(CheckTransaction);