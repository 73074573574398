import { WAIT, WAIT_SUCCESS, WAIT_FAIL, FETCH_RECORDS, FETCH_RECORDS_SUCCESS, FETCH_RECORDS_FAIL } from "./types";
import { delRequest, getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";
import download from "downloadjs";
import { config } from "../../config";
import { deleteObject } from "../../../core/aws";

export const getEvent = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });

    getRequest(data, undefined, undefined, endpoints.guest.event.root)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: FETCH_RECORDS_FAIL });
        });
};

export const joinEvent = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });

    postRequest(data, undefined, undefined, undefined, endpoints.guest.event.join, true)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            dispatch({
                type: WAIT_SUCCESS,
            });
            navigate && navigate(`/guest/event/${data.guest.event}`)
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: WAIT_FAIL });
        });
};

export const sendMessage = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });

    postRequest(data, undefined, undefined, undefined, endpoints.guest.event.message, true)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: WAIT_FAIL });
        });
};

export const uploadFiles = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });

    postRequest(data, undefined, undefined, undefined, endpoints.guest.event.upload, true)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            dispatch({
                type: WAIT_SUCCESS,
            });
            navigate && navigate(-1)
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: WAIT_FAIL });
        });
};

export const getFolders = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });

    getRequest(data, undefined, undefined, endpoints.guest.event.folders, true)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: FETCH_RECORDS_FAIL });
        });
};

export const getUploads = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });

    getRequest(data, undefined, undefined, endpoints.guest.event.upload, true)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: FETCH_RECORDS_FAIL });
        });
};

export const editFile = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });

    putRequest(data, undefined, undefined, undefined, endpoints.guest.event.upload, true)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: FETCH_RECORDS_FAIL });
        });
};

export const deleteFile = (data, file, navigate) => async (dispatch, useState) => {
    dispatch({ type: WAIT });

    await Promise.all([file].map(async (file) => {
        const params = {
            Bucket: config.bucketName,
            Key: file.key
        }
        await deleteObject(params);
    }))

    delRequest(data, undefined, undefined, endpoints.guest.event.upload, true)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            dispatch({
                type: WAIT_SUCCESS
            });
            navigate && navigate(-1)
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: WAIT_FAIL });
        });
};

export const setReminder = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });

    putRequest(data, undefined, undefined, undefined, endpoints.guest.event.reminder, true)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            return dispatch({
                type: WAIT_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: WAIT_FAIL });
        });
};

export const getUploadsEmbed = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });

    getRequest(data, undefined, undefined, endpoints.guest.event.uploadEmbed, true)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: FETCH_RECORDS_FAIL });
        });
};

export const deleteFileEmbed = (data, file, navigate) => async (dispatch, useState) => {
    dispatch({ type: WAIT });

    await Promise.all([file].map(async (file) => {
        const params = {
            Bucket: config.bucketName,
            Key: file.key
        }
        await deleteObject(params);
    }))

    putRequest(data, undefined, undefined, undefined, endpoints.guest.event.uploadEmbed, true)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            dispatch({
                type: WAIT_SUCCESS
            });
            navigate && navigate(-1)
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: WAIT_FAIL });
        });
};