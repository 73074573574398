import * as React from 'react';
import Logo from "../../app/components/logo.png"
import { Typography, Box, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, InputAdornment, IconButton, ImageList, ImageListItem, Grid, Card, CardContent, CardHeader, Pagination, CardActions } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, SecInput, sec_button, ter_button } from '../../app/components/Styles';
import { Image, notification } from 'antd';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { getEvent } from '../../app/store/actions/guestActions';
import { isMobile } from 'react-device-detect';
import { Album, Book, Collections, Delete, Download, Inbox, LibraryMusic, MenuBook, Mic, Notifications, Photo, Upload, Videocam } from '@mui/icons-material';
import { useState } from 'react';
import ReactFileReader from "react-file-reader";
import { capitalize } from 'lodash';
import { v4 as uuid } from 'uuid';
import { config } from '../../app/config';
import { uploadFile } from '../../core/aws';
import { deleteFile, getEvents, getMessages } from '../../app/store/actions/usersActions';
import { useEffect } from 'react';
import moment from 'moment';
import GuestbookCard from '../../app/components/GuestbookCard';

export const Guestbook = ({ getEvents, isLoading, event, messages, getMessages }) => {

    const { eventId } = useParams()
    const navigate = useNavigate()

    const initialGuestbookFilters = {
        page: 1,
        limit: 100,
        eventId,
    }
    const [guestbookFilters, setGuestbookFilters] = useState(initialGuestbookFilters)
    const [action, setAction] = useState(false)
    const { page, limit } = guestbookFilters

    const handlePageChange = (event) => {
        setGuestbookFilters({ ...guestbookFilters, page: parseInt(event.target.innerText) })
    }

    useEffect(() => {
        getMessages(guestbookFilters)
    }, [guestbookFilters])

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h4"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Guestbook
                </Typography>
                <Box sx={{ ...centered_flex_box, flexDirection: "row", width: "100%" }}>

                </Box>
                <hr />
                <Box>
                    <Grid container spacing={2} padding={2} justifyContent="center">
                        {messages?.docs?.map((message) => {
                            return (
                                <GuestbookCard message={message} />
                            )
                        })}
                        {messages?.docs?.length === 0 && (
                                                        <Grid item xs={12} sx={{...centered_flex_box, flexDirection: "column"}}>
                                <Inbox fontSize="large" />
                                <Typography textAlign="center" fontSize={40}>Guestbook Is Empty</Typography>
                            </Grid>
                        )}
                    </Grid>
                    <Box sx={{ ...centered_flex_box, mx: 1, p: 1 }}>
                        <Pagination count={messages?.results?.pages || 1} page={page} onChange={handlePageChange} />
                    </Box>
                </Box>
            </Box>
        </ >
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    event: state?.records?.event,
    messages: state?.records?.messages,
});

const mapDispatchToProps = { getEvents, getMessages };

export default connect(mapStateToProps, mapDispatchToProps)(Guestbook);