import * as React from "react";
import {
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Box,
    MenuItem,
    InputAdornment,
    IconButton,
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    CircularProgress
} from "@mui/material";
import { connect } from "react-redux";
import { MainInput, centered_flex_box, main_button } from "./Styles";
import { isMobile } from "react-device-detect";
import MuiPhoneNumber from "material-ui-phone-number";
import { useNavigate } from "react-router-dom";
import { sendMessage } from "../store/actions/guestActions";
import { Image } from "antd";

function ImageComponent({ src, width="auto", height="auto", preview, style, onClick }) {
    const [isPreview, setIsPreview] = React.useState(false);

    return (
        <>
            {preview && <Dialog
                open={isPreview}
                onClose={() => setIsPreview(false)}
                sx={{ minWidth: "100vw" }}
            >
                <img src={src} loading="lazy" style={{ width: "100%", height: "auto" }} alt="Zoomed" />
            </Dialog >}
            <img src={src} loading="lazy" style={{ height: height, width: width, cursor: preview ? "pointer" : "default", ...style }} alt="Cover" onClick={() => preview ? setIsPreview(true) : onClick ? onClick() : null } />
            {/* <Image src={src} loading="lazy" style={{ height: height, width: width, cursor: preview ? "pointer" : "default", ...style }} alt="Cover" preview={preview}/> */}
        </>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ImageComponent);
