import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
    Typography,
    Box,
    Container,
    TextField,
    CssBaseline,
    Button,
    Avatar,
    Select,
    MenuItem,
    InputLabel,
    FormHelperText,
    FormControl,
    CircularProgress,
    Chip,
    Tooltip,
    Card,
} from "@mui/material";
import { connect } from "react-redux";
import OutboxIcon from "@mui/icons-material/Outbox";
import { uploadFile } from "react-s3";
import {
    centered_flex_box,
    MainInput,
    MainInputLabel,
    MainTextArea,
    main_button,
    reject_button,
    sec_button,
    StyledInput,
} from "../../app/components/Styles";
import { useNavigate, useParams } from "react-router-dom";
import ReactFileReader from "react-file-reader";
import InfoCard from "../../app/components/InfoCard";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

export const Contact = ({ }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation()

    return (
        <Container>
            <Box sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}>
                <Typography
                    fontSize={30}
                    fontWeight="bold"
                    textAlign="center"
                    color={"var(--secColor)"}
                >
                    Contact Us
                </Typography>
                <hr
                    style={{
                        width: "10%",
                        opacity: "100%",
                        "border-top": "solid var(--secColor) 3px",
                    }}
                />
                <Box sx={{ ...centered_flex_box, flexDirection: isMobile ? "column" : "row" }}>
                    <InfoCard title="Email" type={"email"} buttonText="Send" href={"mailto:info@pov-pix.com"} />
                    <InfoCard title="Mobile" type={"mobile"} buttonText="Call" href={"tel:201207325333"} />
                    <InfoCard title="WhatsApp" type={"whatsapp"} buttonText="Text" href={"https://wa.me/201207325333"} />
                </Box>
            </Box>
        </Container>
    );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
