import * as React from 'react';
import Logo from "../../app/components/logo.png"
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, InputAdornment, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, SecInput, sec_button, ter_button, reject_button } from '../../app/components/Styles';
import { Image, notification } from 'antd';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { joinEvent } from '../../app/store/actions/guestActions';
import { isMobile } from 'react-device-detect';
import { Album, Book, Collections, Download, Edit, MenuBook, Mic, Notifications, Print, QrCode, Redo, Upload } from '@mui/icons-material';
import { useState } from 'react';
import MessageModal from '../../app/components/MessageModal';
import ReminderModal from '../../app/components/ReminderModal';
import moment from 'moment';
import { downloadGallery, getEvents } from '../../app/store/actions/usersActions';
import EventInfoModal from '../../app/components/EventInfoModal';
import ImageComponent from '../../app/components/ImageComponent';
import TransferModal from '../../app/components/TransferModal';
import RequestModal from '../../app/components/RequestModal';

export const EventHome = ({ joinEvent, user, downloadGallery, isLoading, event, getEvents }) => {

    const { eventId } = useParams()
    const [messageModal, setMessageModal] = useState(false);
    const [reminderModal, setReminderModal] = useState(false);
    const [transferModal, setTransferModal] = useState(false);
    const [linkModal, setLinkModal] = useState(false);
    const [requestModal, setRequestModal] = useState(false);
    const navigate = useNavigate()

    React.useEffect(() => {
        getEvents({ eventId })
    }, [eventId]);

    if(event?.expired) {
        notification.error({message: "Event Expired", style: { marginTop: "15vh" }})
        navigate("/user/events");
    }

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }


    return (
        <Container component="main" maxWidth="xs">
            <MessageModal eventId={eventId} open={messageModal} handleClose={() => setMessageModal(false)} />
            <ReminderModal eventId={eventId} open={reminderModal} handleClose={() => setReminderModal(false)} />
            <TransferModal eventId={eventId} open={transferModal} handleClose={() => setTransferModal(false)} />
            <EventInfoModal event={event} open={linkModal} handleClose={() => setLinkModal(false)} />
            <RequestModal event={event} open={requestModal} handleClose={() => setRequestModal(false)} />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                {event?.cover && <ImageComponent src={event?.cover} width={isMobile ? "100vw" : "auto"} height={isMobile ? "auto" : "30vh"} preview={true}/>}
                <Container disableGutters maxWidth="xl" component="main" sx={{ pt: 1 }}>
                    <Typography
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        {event?.name}
                    </Typography>
                    <Typography
                        variant="h5"
                        textAlign="center"
                        color="text.secondary"
                        gutterBottom
                    >
                        {event?.guests?.length} / {event?.bundle.maxUsers} Guests
                    </Typography>
                    <Typography
                        variant="h5"
                        textAlign="center"
                        color="text.secondary"
                        gutterBottom
                    >
                        {moment(event?.date).format("ll")}
                    </Typography>
                </Container>
                <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100%" }}>
                    <Button sx={{ ...main_button, my: 1 }} fullWidth onClick={() => setLinkModal(true)}><QrCode sx={{ mx: 1 }} /> Event Link / QR Code</Button>
                    <Button sx={{ ...main_button, my: 1 }} fullWidth onClick={() => navigate(`/user/events/edit/${eventId}`)}><Edit sx={{ mx: 1 }} /> Edit Event</Button>
                    <Button sx={{ ...main_button, my: 1 }} fullWidth onClick={() => navigate(`/user/events/upload/${eventId}`)}><Upload sx={{ mx: 1 }} /> Upload Media</Button>
                    <Button sx={{ ...main_button, my: 1 }} fullWidth onClick={() => navigate(`/user/events/guestbook/${eventId}`)}><MenuBook sx={{ mx: 1 }} /> {event?.bundle.name === "Wedding" ? "View Guestbook Messages" : "View Messages"}</Button>
                    <Button sx={{ ...main_button, my: 1 }} fullWidth onClick={() => navigate(`/user/events/gallery/${eventId}`)}><Collections sx={{ mx: 1 }} /> View Gallery</Button>
                    <Button sx={{ ...ter_button, my: 1 }} fullWidth onClick={() => setRequestModal(true)}><Print sx={{ mx: 1 }} /> Request Printing Service</Button>
                    <Button sx={{ ...ter_button, my: 1 }} fullWidth onClick={() => downloadGallery({ eventId })}><Download sx={{ mx: 1 }} /> Download All Media</Button>
                    <Button sx={{ ...reject_button, my: 1 }} fullWidth onClick={() => setTransferModal(true)}><Redo sx={{ mx: 1 }} /> Transfer Event</Button>
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    event: state?.records?.event,
});

const mapDispatchToProps = { getEvents, downloadGallery };

export default connect(mapStateToProps, mapDispatchToProps)(EventHome);