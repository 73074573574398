import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { About, CheckTransaction, ComingSoon, Contact, CreateEvent, Demo, EditEvent, EventDashboard, EventHome, EventWelcome, Events, FAQs, ForgotPassword, Gallery, GuestGallery, Guestbook, Home, HostUpload, Login, Package, Pricing, Privacy, Profile, RecordAudio, RedeemVoucher, Refund, ResetPassword, Terms, UploadFiles, UserOrders, Verify } from "./Pages";
import ProtectedRoute from "./ProtectedRoute";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/login" exact element={<Login />} />
      <Route path="/reset-password/:token" exact element={<ResetPassword />} />
      <Route path="/forgot-password" exact element={<ForgotPassword />} />
      <Route path="/about" exact element={<About />} />
      <Route path="/demo" exact element={<Demo />} />
      <Route path="/faq" exact element={<FAQs />} />
      <Route path="/pricing/category/:category" exact element={<Pricing />} />
      <Route path="/pricing/:bundleId" exact element={<Package />} />
      <Route path="/pricing" exact element={<Pricing />} />
      <Route path="/post_pay" exact element={<CheckTransaction />} />
      <Route path="/terms" exact element={<Terms />} />
      <Route path="/policy/refund" exact element={<Refund />} />
      <Route path="/policy/privacy" exact element={<Privacy />} />
      <Route path="/contact" exact element={<Contact />} />
      <Route path="/verify/:token" exact element={<Verify />} />
      <Route exact element={<ProtectedRoute allowed={["User"]} />}>
        <Route path="/user/voucher" exact element={<RedeemVoucher />} />
        <Route path="/user/orders/:orderId" exact element={<ComingSoon />} />
        <Route path="/user/orders" exact element={<UserOrders />} />
        <Route path="/user/events/:eventId" exact element={<EventDashboard />} />
        <Route path="/user/events/upload/:eventId" exact element={<HostUpload />} />
        <Route path="/user/events/gallery/:eventId" exact element={<Gallery />} />
        <Route path="/user/events/guestbook/:eventId" exact element={<Guestbook />} />
        <Route path="/user/events/create/:orderId" exact element={<CreateEvent />} />
        <Route path="/user/events/edit/:eventId" exact element={<EditEvent />} />
        <Route path="/user/events" exact element={<Events />} />
        <Route path="/user/account" exact element={<Profile />} />
      </Route>
      <Route path="/guest/event/welcome/:eventId" exact element={<EventWelcome />} />
      <Route path="/guest/event/welcome/:eventId/:pin" exact element={<EventWelcome />} />
      <Route exact element={<ProtectedRoute allowed={["Guest"]} />}>
        <Route path="/guest/event/:eventId" exact element={<EventHome />} />
        <Route path="/guest/event/upload/:eventId" exact element={<UploadFiles />} />
        <Route path="/guest/event/audio/:eventId" exact element={<RecordAudio />} />
        <Route path="/guest/event/gallery/:eventId" exact element={<GuestGallery />} />
      </Route>
      <Route path="/guest/event/gallery/embed/:eventId/:cookieId" exact element={<GuestGallery />} />
    </Routes>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
