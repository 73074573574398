import * as React from "react";
import {
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Box,
    MenuItem,
    InputAdornment,
    IconButton,
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    CircularProgress
} from "@mui/material";
import { connect } from "react-redux";
import { MainInput, centered_flex_box, main_button } from "./Styles";
import { isMobile } from "react-device-detect";
import MuiPhoneNumber from "material-ui-phone-number";
import { useNavigate } from "react-router-dom";
import { transferEvent } from "../store/actions/usersActions";

function TransferModal({ open, handleClose, eventId, transferEvent, isWaiting }) {

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const transferInfo = {
            eventId,
            email: data.get('email'),
            message: data.get('message'),
        }
        transferEvent(transferInfo, navigate)
        handleClose()
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ color: "var(--appBg)", minWidth: "100vw" }}
        >
            <Box component="form" onSubmit={handleSubmit} sx={{ padding: 2 }}>
                <Typography variant="h4">Transfer Event</Typography>
                <Typography variant="h6" fontWeight="light">Transfer the event to another user (new or existing)</Typography>
                <MainInput
                    margin="normal"
                    fullWidth
                    required
                    type="email"
                    name="email"
                    label="Email"
                    id="email"
                />
                <MainInput
                    margin="normal"
                    fullWidth
                    multiline
                    minRows={3}
                    inputProps={{
                        maxlength: 144,
                    }}
                    name="message"
                    label="Message"
                    id="message"
                />
                <Button type='submit' sx={{ ...main_button }} disabled={isWaiting} fullWidth>{isWaiting ? <CircularProgress sx={{ color: "var(--secColor)" }} /> : "Transfer"}</Button>
            </Box>
        </Dialog >
    );
}

const mapStateToProps = (state) => ({
    isWaiting: state?.wait?.isLoading
});

const mapDispatchToProps = { transferEvent };

export default connect(mapStateToProps, mapDispatchToProps)(TransferModal);
