import { Circle, Mic, Pause, PlayArrow, RecordVoiceOver, Save, Share, Stop } from '@mui/icons-material';
import { Box, Card, CardActions, CardContent, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import { centered_flex_box } from './Styles';
import { isAndroid, isChrome, isIOS, isMobile, isSafari } from 'react-device-detect';

function getSupportedMimeTypes(media, types, codecs) {
    const isSupported = MediaRecorder.isTypeSupported;
    const supported = [];
    types.forEach((type) => {
        const mimeType = `${media}/${type}`;
        if (isSupported(mimeType))
            supported.push({ mimeType, extension: type });
    });
    return supported;
};

function RecordAudioCard({ addAudioElement, filesLength }) {

    let mimeType = "audio/webm";
    const audioTypes = ["webm", "ogg", "mp3", "mp4", "wav", "x-matroska"];
    const supportedAudios = getSupportedMimeTypes("audio", audioTypes, []);
    mimeType = supportedAudios[0].mimeType;

    const {
        startRecording,
        stopRecording,
        togglePauseResume,
        recordingBlob,
        isRecording,
        isPaused,
        recordingTime,
        mediaRecorder
    } = useAudioRecorder({
        noiseSuppression: true,
        echoCancellation: true
    }, undefined, { mimeType });


    const handleDone = () => {
        stopRecording();
        const file = new File([recordingBlob], `AudioRecording_${filesLength + 1}.${supportedAudios[0].extension}`, { type: mimeType });
        addAudioElement([file]);
    }

    return (
        <Box width="100%">
            <Card>
                <CardContent sx={{ ...centered_flex_box, flexDirection: "column" }}>
                    <Typography textAlign="center" variant='h6'>Record Audio</Typography>
                    {isRecording && <Typography textAlign="center"><Circle sx={{ color: isPaused ? "black !important" : "red !important", width: 0.05, mx: 1 }} />{moment.utc(moment.duration(recordingTime, 'seconds').asMilliseconds()).format("mm:ss")}</Typography>}
                    {isRecording && mediaRecorder && <LiveAudioVisualizer
                        mediaRecorder={mediaRecorder}
                        barColor="var(--secColor)"
                        width={200}
                        height={75}
                    />}
                </CardContent>
                <CardActions>
                    {!isRecording && <IconButton onClick={() => startRecording()}><Mic sx={{ color: "var(--secColor)" }} /></IconButton>}
                    {isRecording && <IconButton onClick={stopRecording}><Stop sx={{ color: "var(--secColor)" }} /></IconButton>}
                    {isRecording && <IconButton onClick={togglePauseResume}>{isPaused ? <PlayArrow sx={{ color: "var(--secColor)" }} /> : <Pause sx={{ color: "var(--secColor)" }} />}</IconButton>}
                    {recordingBlob && <IconButton onClick={handleDone}><Save sx={{ color: "var(--accColor)" }} /></IconButton>}
                </CardActions>
            </Card>
        </Box>
    )
}

export default RecordAudioCard;
