import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { starFile } from '../store/actions/usersActions';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import ImageComponent from './ImageComponent';
import { Image } from 'antd';
import { Star, StarOutline } from '@mui/icons-material';

function srcset(image, starred) {
    return {
        src: image,
        style: { width: starred ? "80vw" : "40vw", height: starred ? "80vw" : "40vw" }
    };
}

function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
}

function GalleryView({ user, files, eventId, event, editFile, deleteFile, guestEdit, guestDelete, action, setAction, guest, cookieId, starFile }) {

    const guestCookie = Cookies.get(`guestId_${eventId}`) || cookieId;
    console.log(guestCookie)

    const handleStar = async (file) => {
        if( user?._id === event?.user ) {
            starFile({ eventId, uploadId: file._id, star: file.starred ? false : true })
            timeout(200)
            setAction(!action)
        }
    }

    return (
        <ImageList
            sx={{
                // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
                transform: 'translateZ(0)',
            }}
            rowHeight={window.screen.width * 0.4}
            gap={1}
        >
            {files?.map((file) => {
                const cols = file.starred ? 2 : 1;
                const rows = file.starred ? 2 : 1;
                return (
                    <ImageListItem key={file.link} cols={cols} rows={rows}>
                        {file.type === "Image" && <Image
                            src={file.link}
                            style={{ width: window.screen.width * rows, height: window.screen.width * rows }}
                            alt={file.title}
                        />}
                        {file.type === "Video" && <video
                            autoPlay={false} 
                            controls
                            src={file.link}
                            style={{ width: window.screen.width * rows, height: window.screen.width * rows }}
                            alt={file.title}
                        />}
                        <ImageListItemBar
                            sx={{
                                background:
                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                            }}
                            title={file.user?.name || file.guest?.name}
                            position="top"
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'white' }}
                                    aria-label={`star ${file._id}`}
                                    onClick={() => handleStar(file)}
                                >
                                    {file.starred ? <Star sx={{color: "var(--secColor)"}}/> : user?._id === event?.user && <StarOutline />}
                                </IconButton>
                            }
                            actionPosition="left"
                        />
                    </ImageListItem>
                );
            })}
        </ImageList>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
});

const mapDispatchToProps = { starFile };

export default connect(mapStateToProps, mapDispatchToProps)(GalleryView);